import Quill from 'quill';
// import Inline from 'quill/blots/inline';
const Inline = Quill.import('blots/inline');

class GlossaryKeyword extends Inline {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('data-glossary-keyword', value);
    return node;
  }

  static formats(domNode) {
    return domNode.getAttribute('data-glossary-keyword');
  }

  format(name, value) {
    if (name !== this.statics.blotName || !value) return super.format(name, value);
    this.domNode.setAttribute('data-glossary-keyword', value);
    return null;
  }
}
GlossaryKeyword.blotName = 'glossaryKeyword';
GlossaryKeyword.tagName = 'SPAN';

export { GlossaryKeyword as default };
