import { ActionTree } from 'vuex';
import parseHydraDocumentation
  from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { SET_API } from '@/store/api/mutation-types';
import { ApiState } from '@/types/store/ApiState';
import { RootState } from '@/types/store/RootState';

const actions: ActionTree<ApiState, RootState> = {
  async loadApiDocumentation({ commit }) {
    const scheme = process.env.VUE_APP_API_HTTPS === 'true' ? 'https' : 'http';
    const docs = await parseHydraDocumentation(
      `${scheme}://`
        + `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}`
        + `${process.env.VUE_APP_API_ROUTE}${process.env.VUE_APP_API_DOCS}`,
    );
    if (docs.api) {
      commit(SET_API, { ...docs.api });
    }
  },
};

export default actions;
