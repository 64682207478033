/* eslint-disable no-param-reassign */

import { Component } from 'vue-property-decorator';
import { Template, TemplateSegment } from '@/types/resources/Template';
import { contentBlockTypes } from '@/utils/ContentBlockTypes';
import { Translatable } from '@/types/resources/Translatable';
import {
  CollectionContentBlockInterface,
  ContentBlockInterface,
} from '@/types/resources/ContentBlockInterface';
import { ContentBlockDefinition } from '@/types/interfaces/Template/TemplateSegmentConfiguration';
import { Content } from '@/types/resources/Content';
import { mixins } from 'vue-class-component';
import FileContentBlockMixin from '@/mixins/ContentBlocks/FileContentBlockMixin';
import ImageContentBlockMixin from '@/mixins/ContentBlocks/ImageContentBlockMixin';
import ReferenceContentBlockMixin from '@/mixins/ContentBlocks/ReferenceContentBlockMixin';
import TextContentBlockMixin from '@/mixins/ContentBlocks/TextContentBlockMixin';
import CollectionContentBlockMixin from '@/mixins/ContentBlocks/CollectionContentBlockMixin';
import OsappModelViewContentBlockMixin from '@/mixins/ContentBlocks/OsappModelViewContentBlockMixin';
import OsappAnimationViewContentBlockMixin from '@/mixins/ContentBlocks/OsappAnimationViewContentBlockMixin';
import CourseQuestionContentBlockMixin
  from '@/mixins/ContentBlocks/CourseQuestionContentBlockMixin';
import ChoiceContentBlockMixin from '@/mixins/ContentBlocks/ChoiceContentBlockMixin';
import JsonContentBlockMixin from '@/mixins/ContentBlocks/JsonContentBlockMixin';

@Component
export default class CreateContentBlockMixin extends mixins(
  mixins(TextContentBlockMixin,
    ChoiceContentBlockMixin, JsonContentBlockMixin, ImageContentBlockMixin, FileContentBlockMixin),
  mixins(CollectionContentBlockMixin, OsappAnimationViewContentBlockMixin, OsappModelViewContentBlockMixin, CourseQuestionContentBlockMixin, ReferenceContentBlockMixin), // Ugly workaround for https://github.com/vuejs/vue-class-component/issues/429
) {
  protected content!: Partial<Content>;

  getContentBlockComponentFromType(type: string): string {
    return `${type.slice(0, 1).toUpperCase()}${type.slice(1)}ContentBlockForm`;
  }

  initializeContentBlock(
    contentBlockDefinition: ContentBlockDefinition,
    block: Partial<ContentBlockInterface>,
    name: string,
  ): Partial<ContentBlockInterface> {
    // Add the contentBlock's data for translatable sections
    if (Object.keys(contentBlockDefinition.translatable).length > 0) {
      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations) {
          block.translations[locale] = {
            ...contentBlockDefinition.translatable,
            name,
            locale,
          };
        }
      });
    }
    // Non-translatable sections: Store on top level
    block = {
      ...block, // copy prepared block from above
      ...contentBlockDefinition.defaultBlock,
    };

    if (!block.translations) {
      block.translations = {};
    }
    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (!block.translations[locale]) {
        block.translations[locale] = {};
      }
      block.translations[locale].name = name;
      block.translations[locale].locale = locale;
    });

    if (block.type === 'collection') {
      this.initializeCollectionContentBlock(block);
    }

    return block;
  }

  initializeCollectionContentBlock(block: Partial<CollectionContentBlockInterface>) {
    block.contentBlocks = [];
  }

  initializeContentBlockFromSegment(s: Partial<TemplateSegment>) {
    if (this.content?.contentBlocks !== undefined) {
      // Get contentBlockDefinition for default values and to check if it's translatable
      const contentBlockDefinition = Object.values(contentBlockTypes)
        .find((b) => b.name === s.contentBlockType);

      if (contentBlockDefinition) {
        // Prepare the content block
        let block: Partial<Translatable<ContentBlockInterface>> = {
          type: s.contentBlockType,
          name: s.name,
          config: s.config,
          templateSegment: s['@id'],
          translations: {},
        };

        block = this.initializeContentBlock(contentBlockDefinition, block, s.name as string);

        // Add the contentBlock to the content
        this.content.contentBlocks.push(block);
      }
    }
  }

  initializeContentBlocksFromTemplate(value: Template) {
    // Reset contentBlocks
    this.content.contentBlocks = [];

    // Loop over segments from Template
    value.segments.forEach((s) => {
      this.initializeContentBlockFromSegment(s);
    });
  }
}
