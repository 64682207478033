import { MutationTree } from 'vuex';
import { LOGIN, LOGOUT } from '@/store/auth/mutation-types';
import { AuthState } from '@/types/store/AuthState';
import { User } from '@/types/resources/User';

const mutations: MutationTree<AuthState> = {
  [LOGIN](state, payload: User) {
    state.user = payload;
    state.isLoggedIn = true;
  },

  [LOGOUT](state) {
    state.user = undefined;
    state.isLoggedIn = false;

    if (window?.localStorage) {
      window.localStorage.removeItem('user');
    }
  },
};

export default mutations;
