/* eslint-disable no-param-reassign, no-underscore-dangle */

import { extend, configure } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import i18n from '@/i18n';

configure({
  // See https://logaretm.github.io/vee-validate/guide/localization.html#using-other-i18n-libraries
  defaultMessage(field, values) {
    if (values) {
      values._field_ = i18n.t(`fields.${field}`);

      return i18n.t(`validation.${values._rule_}`, values) as string;
    }

    return i18n.t('validation.general') as string;
  },
});

// register rules
extend('required', required);
extend('email', email);
