import { render, staticRenderFns } from "./CourseQuestionContentBlockForm.vue?vue&type=template&id=76623c68&"
import script from "./CourseQuestionContentBlockForm.vue?vue&type=script&lang=ts&"
export * from "./CourseQuestionContentBlockForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports