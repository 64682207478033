import { Module } from 'vuex';
import getters from '@/store/listView/getters';
import actions from '@/store/listView/actions';
import mutations from '@/store/listView/mutations';
import { ListViewState } from '@/types/store/ListViewState';
import { RootState } from '@/types/store/RootState';

export const state: ListViewState = {};

const namespaced = true;

export const listView: Module<ListViewState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
