
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';

@Component({
  components: { ContentBlockFormWrapper, ValidationProvider },
})
export default class JsonContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<ContentBlockInterface>;

  get textJson() {
    if (this.syncedContentBlock && this.syncedContentBlock.translations[this.locale].json) {
      return JSON.stringify(this.syncedContentBlock?.translations[this.locale].json);
    }
    return '{}';
  }

  set textJson(val) {
    try {
      this.$set(this.syncedContentBlock.translations[this.locale], 'json', JSON.parse(val));
    } catch (e) {
      console.error(e);
    }
  }
}
