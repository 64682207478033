
import {
  Vue, Component, PropSync, Prop, Watch,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';

@Component({
  components: { ContentBlockFormWrapper, ValidationProvider },
})
export default class ChoiceContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<ContentBlockInterface>;

  @Watch('choices', { deep: true })
  onPropertyChanged(value: Translatable<ContentBlockInterface>) {
    this.syncedContentBlock.choices = value;
  }

  choices: Array<string> = [];

  mounted() {
    this.$nextTick(async () => {
      if (
        !this.syncedContentBlock.config
        && this.syncedContentBlock.templateSegment
        && typeof this.syncedContentBlock.templateSegment === 'object'
      ) {
        const response = await this.$api.get(this.syncedContentBlock.templateSegment['@id']);
        if (response.status === 200) {
          this.$set(this.syncedContentBlock, 'config', response.data.config);
        }
      }
      this.choices = this.syncedContentBlock.choices;
    });
  }
}
