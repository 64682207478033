import { GetterTree } from 'vuex';
import { RootState } from '@/types/store/RootState';
import { HierarchyState } from '@/types/store/HierarchyState';
import { HierarchyNode } from '@/types/resources/HierarchyNode';

const getters: GetterTree<HierarchyState, RootState> = {
  getHierarchyNodes(state): HierarchyNode[] {
    return state.hierarchyNodes;
  },

  getHierarchyNode(state): (id: string) => HierarchyNode | undefined {
    return (id) => state.hierarchyNodes.find((node) => node.id === id);
  },
};

export default getters;
