
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import { Treeselect, ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { ValidationProvider } from 'vee-validate';
import { FileContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { MediaObject } from '@/types/resources/MediaObject';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import { asyncForEach } from '@/utils/asyncForEach';

@Component({
  components: { ContentBlockFormWrapper, Treeselect, ValidationProvider },
})
export default class FileContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: FileContentBlockInterface;

  private uploading = false;

  private file: File | null = null;

  private error = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async loadMediaObjects({ action, searchQuery, callback }: any) {
    if (action === ASYNC_SEARCH) {
      const response = await this.$api.get('/api/media_objects', {
        params: { filename: searchQuery },
      });
      if (response.status === 200 && response.data['hydra:member']) {
        callback(null, response.data['hydra:member'].map((el: MediaObject) => ({
          id: el['@id'],
          label: el.filename,
        })));
      }
    }
  }

  async uploadMediaObject() {
    this.uploading = true;
    if (this.file) {
      if (this.file) {
        const form = new FormData();
        form.append('file', this.file);

        try {
          const response = await this.$api.post('/api/media_objects', form, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          if (response.status === 201 && response.data) {
            this.syncedContentBlock.translations[this.locale].file = response.data['@id'];

            this.$nextTick(() => {
              this.$forceUpdate();
              this.$nextTick(() => {
                // This line loads the name in Treeselect's cache so it has a label
                // eslint-disable-next-line
                (this.$refs[`${this.syncedContentBlock.name.replace(' ', '-')}_${this.syncedContentBlock.position}_${this.locale}`] as any)
                  .forest.nodeMap[this.syncedContentBlock.translations[this.locale].file]
                  .label = `${response.data.filename}`;
                this.file = null;
              });
            });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          if (e.response?.data?.['hydra:description']) {
            this.error = e.response.data['hydra:description'];
          }
        }
      }
    }
    this.uploading = false;
  }

  /**
   * Load Filenames and Caption of all Images for all Translations
   */
  async mounted() {
    /* eslint-disable */
    await asyncForEach(Object.keys(this.syncedContentBlock.translations), async (locale: string) => {
      if (locale === this.locale) {
        if (typeof this.syncedContentBlock.translations[locale].file === 'string') {
          const response = await this.$api.get(this.syncedContentBlock.translations[locale].file);
          if (response.status === 200) {
            (this.$refs[this.syncedContentBlock.name.replace(' ', '-') + '_' + this.syncedContentBlock.position + '_' + this.locale] as any)
              .forest.nodeMap[this.syncedContentBlock.translations[locale].file].label
              = response.data.filename;
          }
        }
      }
    });
    /* eslint-enable */
  }
}
