import Vue from 'vue';
import Vuex from 'vuex';
import { RootState } from '@/types/store/RootState';
import { hierarchy } from '@/store/hierarchy';
import { auth } from './auth';
import { api } from './api';
import { listView } from './listView';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    api,
    listView,
    hierarchy,
  },
});
