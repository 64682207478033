import { Component, Vue } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';

@Component
export default class TranslationsMixin extends Vue {
  setPropertiesForAllLocales<T>(object: T, properties: { [P in keyof T]?: T[P] }): Translatable<T> {
    const translatable: Partial<Translatable<T>> = {
      translations: {},
    };
    this.$root.$i18n.availableLocales.forEach((locale) => {
      if (translatable.translations) {
        translatable.translations[locale] = { ...properties, locale };
      }
    });

    return translatable as Translatable<T>;
  }
}
