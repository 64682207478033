import { MutationTree } from 'vuex';
import { SET_HIERARCHY_NODES } from '@/store/hierarchy/mutation-types';
import { HierarchyState } from '@/types/store/HierarchyState';

const mutations: MutationTree<HierarchyState> = {
  [SET_HIERARCHY_NODES](state, payload) {
    state.hierarchyNodes = payload;
  },
};

export default mutations;
