import { GetterTree } from 'vuex';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import { ApiState } from '@/types/store/ApiState';
import { RootState } from '@/types/store/RootState';

const getters: GetterTree<ApiState, RootState> = {
  getResources(state): Resource[] {
    return state.resources;
  },
};

export default getters;
