/* eslint-disable no-param-reassign */

import { Vue, Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';

@Component
export default class ChoiceContentBlockMixin extends Vue {
  async loadChoiceContentBlock(block: Translatable<ContentBlockInterface>) {
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
      }

      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations[locale] === undefined) {
          block.translations[locale] = {
            id: undefined,
            name: block.name,
            locale,
          };
        }
      });
    }
  }

  prepareChoiceContentBlock(block: Translatable<ContentBlockInterface>):
    Partial<Translatable<ContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<ContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'choice',
      choices: block.choices,
    };

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        preparedBlock.translations[locale] = {
          id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
          templateSegment,
          name: block.translations[locale].name,
          locale,
        };
      }
    });

    return preparedBlock;
  }
}
