export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      guest: true,
    },
  },
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':resourceTitle/create/:templateId',
        name: 'create-resource-with-template',
        component: () => import(/* webpackChunkName: "create-resource" */ '../views/CreateResource.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':resourceTitle/:resourceId/edit',
        name: 'edit-resource',
        component: () => import(/* webpackChunkName: "edit-resource" */ '../views/EditResource.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':resourceTitle/create',
        name: 'create-resource',
        component: () => import(/* webpackChunkName: "create-resource" */ '../views/CreateResource.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':resourceTitle/:resourceId',
        name: 'view-resource',
        component: () => import(/* webpackChunkName: "view-resource" */ '../views/ViewResource.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'contents/hierarchy/:hierarchyNodeId',
        name: 'contents-hierarchy',
        component: () => import(/* webpackChunkname: "contents-hierarchy" */ '../views/contents/HierarchyView.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':resourceTitle',
        name: 'list-resource',
        component: () => import(/* webpackChunkName: "list-resource" */ '../views/ListResource.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
