
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { User } from '@/types/resources/User';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';

@Component({
  components: { ValidationProvider },
})
export default class UserEditForm extends Vue implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: User;

  private user = {
    email: '',
    password: '',
    roles: ([] as string[]),
  };

  private roles = [
    'ROLE_LTI_USER',
    'ROLE_USER',
    'ROLE_REVIEWER',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN',
  ]

  get isAdmin() {
    return this.$store.state.auth.user?.roles?.includes('ROLE_SUPER_ADMIN');
  }

  loadLocalResource() {
    this.user.email = this.resource.email;
    this.user.password = this.resource.password ?? '';
    this.user.roles = this.resource.roles ?? [];
  }

  prepareLocalResource() {
    const userToSend: Partial<User> = {};
    if (this.user.email) {
      userToSend.email = this.user.email;
    }
    if (this.user.password) {
      userToSend.password = this.user.password;
    }
    if (this.user.roles) {
      userToSend.roles = this.user.roles;
    }

    return userToSend;
  }

  resetLocalResource() {
    this.loadLocalResource();
  }

  @Watch('resource')
  resourceChanged() {
    this.loadLocalResource();
  }
}
