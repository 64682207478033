/* eslint-disable no-param-reassign */

import { Vue, Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { JsonContentBlockInterface } from '@/types/resources/ContentBlockInterface';

@Component
export default class JsonContentBlockMixin extends Vue {
  async loadJsonContentBlock(block: Translatable<JsonContentBlockInterface>) {
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
      }

      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations[locale] === undefined) {
          block.translations[locale] = {
            id: undefined,
            json: '',
            name: block.name,
            locale,
          };
        }
      });
    }
  }

  prepareJsonContentBlock(block: Translatable<JsonContentBlockInterface>):
    Partial<Translatable<JsonContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<JsonContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      type: 'json',
    };

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.translations[locale].json) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            json: block.translations[locale]?.json,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    // eslint-disable-next-line
    // @ts-ignore
    if (Object.keys(preparedBlock.translations).length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
