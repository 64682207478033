import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import _Vue from 'vue';

const defaultHeaders = {
  common: {
    'Content-Type': 'application/json; charset=utf-8',
  },
};

export default function AxiosPlugin(Vue: typeof _Vue, options?: AxiosRequestConfig) {
  /* eslint-disable-next-line no-param-reassign */
  Vue.prototype.$api = axios.create(options);

  Vue.prototype.$api.interceptors.response.use(
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    (response: AxiosResponse) => response,
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    (error: AxiosError) => {
      if (error.response?.status === 401 && window) {
        window.location.href = process.env.VUE_APP_LOGIN_ROUTE;
      }
      return Promise.reject(error);
    },
  );

  /**
   * Workaround for Headers to be set correctly
   * https://github.com/axios/axios/issues/2623
   * */
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  Vue.prototype.$api.interceptors.request.use((config: any) => {
    if (config.method) {
      const requestMethod = config.method.toLowerCase();
      const commonHeaders = Reflect.get(defaultHeaders, 'common', {});
      const methodHeaders = Reflect.get(defaultHeaders, requestMethod, {});

      const headers = { ...config.headers, ...commonHeaders, ...methodHeaders };
      return { ...config, headers };
    }

    return config;
  });
}
