import { ContentBlockDefinition } from '@/types/interfaces/Template/TemplateSegmentConfiguration';

export const contentBlockTypes: Record<string, ContentBlockDefinition> = {
  text: {
    name: 'text',
    label: 'TextContentBlock',
    config: {
      multiline: 'boolean',
      wysiwyg: 'boolean',
    },
    defaultBlock: {
      text: '',
    },
    translatable: { },
  },
  json: {
    name: 'json',
    label: 'JsonContentBlock',
    config: null,
    defaultBlock: {
      json: {},
    },
    translatable: { },
  },
  image: {
    name: 'image',
    label: 'ImageContentBlock',
    config: null,
    translatable: { },
    defaultBlock: {
      image: null,
    },
  },
  file: {
    name: 'file',
    label: 'FileContentBlock',
    config: null,
    translatable: { },
    defaultBlock: {
      file: null,
    },
  },
  reference: {
    name: 'reference',
    label: 'ReferenceContentBlock',
    translatable: { },
    config: {
      mode: {
        type: 'string',
        choices: ['modal', 'search'],
      },
      allowedTemplates: {
        type: 'array',
      },
    },
    defaultBlock: {
      reference: null,
    },
  },
  collection: {
    name: 'collection',
    label: 'ContentBlockCollection',
    translatable: { },
    defaultBlock: {
      contentBlocks: [],
    },
    config: {
      allowedTypes: {
        type: 'array',
        choices: ['text', 'image', 'file', 'reference'],
      },
      minTotal: {
        type: 'number',
      },
      maxTotal: {
        type: 'number',
      },
    },
  },
  osappModelView: {
    name: 'osappModelView',
    label: 'OsappModelViewContentBlock',
    config: null,
    translatable: { },
    defaultBlock: {
      configuration: {},
      cameraConfiguration: {},
      allowPanning: false,
    },
  },
  osappAnimationView: {
    name: 'osappAnimationView',
    label: 'OsappAnimationViewContentBlock',
    config: null,
    translatable: { },
    defaultBlock: {
      configuration: {},
      cameraConfiguration: {},
      allowPanning: false,
    },
  },
  choice: {
    name: 'choice',
    label: 'ChoiceContentBlock',
    translatable: { },
    defaultBlock: {
      choices: [],
    },
    config: {
      choices: {},
    },
  },
  courseQuestion: {
    name: 'courseQuestion',
    label: 'CourseQuestionContentBlock',
    defaultBlock: {
      questionType: 'singleChoice',
    },
    translatable: {
      questionData: {},
      answerData: {},
      questionText: '',
      answerText: '',
    },
    config: {},
  },
};

export const exampleCollectionConfig = {
  allowedTypes: ['text', 'image', 'file', 'reference'],
  minTotal: 1,
  maxTotal: 100,
};

export const exampleTextConfig = {
  multiline: false,
  wysiwyg: true,
};

export const exampleReferenceConfig = {
  mode: 'modal',
  allowedTemplates: [
    '00000000-0000-0000-0000-000000000000',
  ],
};
