import { Template, TemplateSegment } from '@/types/resources/Template';

export enum HierarchyNodeType {
  none = 'none',
  segmentOnParent = 'segment_on_parent',
  segmentOnChild = 'segment_on_child',
}

export interface HierarchyNode {
  '@id': string;
  id?: string;
  name: string;
  position: number;
  type: HierarchyNodeType;
  template: string | Template | null;
  segment: string | TemplateSegment | null;
  children: Partial<HierarchyNode>[];
  onlyRoots: boolean;
}
