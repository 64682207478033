import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faArrowLeft,
  faCogs,
  faEye,
  faFileAlt,
  faFileImport,
  faFileUpload,
  faFilter,
  faFolderTree,
  faGripLinesVertical,
  faHamburger,
  faImages,
  faList,
  faPencil,
  faPlus as faPlusDuo,
  faPlusSquare,
  faSitemap,
  faSpinner,
  faTimes,
  faTrash,
  faUser,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons';

import {
} from '@fortawesome/pro-solid-svg-icons';

import {
  faPlus,
  faMinus,
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faArrowLeft,
  faCogs,
  faEye,
  faFileAlt,
  faFileImport,
  faFileUpload,
  faFilter,
  faFolderTree,
  faGripLinesVertical,
  faHamburger,
  faImages,
  faList,
  faMinus,
  faPencil,
  faPlus,
  faPlusDuo,
  faPlusSquare,
  faSitemap,
  faSpinner,
  faTimes,
  faTrash,
  faUser,
  faUsers,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
