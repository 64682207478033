
import { Component } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import Draggable from 'vuedraggable';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import { Template } from '@/types/resources/Template';
import TemplateSegmentForm from '@/components/resources/Template/TemplateSegmentForm.vue';
import { mixins } from 'vue-class-component';
import TemplateMixin from '@/mixins/Template/TemplateMixin';
import { TemplateStatus } from '@/types/Enums';

@Component({
  components: { ValidationProvider, TemplateSegmentForm, Draggable },
})
export default class TemplateCreateForm
  extends mixins(TemplateMixin) implements CreateFormInterface {
  protected template: Partial<Template> = {};

  resetLocalResource(): void {
    this.template = {
      name: '',
      status: TemplateStatus.published,
      segments: [],
    };

    this.addTemplateSegment();
  }
}
