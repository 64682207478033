import { GetterTree } from 'vuex';
import { BvTableField } from 'bootstrap-vue';
import { ListViewState } from '@/types/store/ListViewState';
import { RootState } from '@/types/store/RootState';

const getters: GetterTree<ListViewState, RootState> = {
  showPropertiesForResource(state): (resourceName: string) => Array<BvTableField> {
    return (resourceName: string) => state[resourceName];
  },
};

export default getters;
