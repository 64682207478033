import { GetterTree } from 'vuex';
import { AuthState } from '@/types/store/AuthState';
import { RootState } from '@/types/store/RootState';

const getters: GetterTree<AuthState, RootState> = {
  isAdmin(state): boolean {
    const { user } = state;
    if (user) {
      return user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN');
    }

    return false;
  },
  isSuperAdmin(state): boolean {
    const { user } = state;
    if (user) {
      return user.roles.includes('ROLE_SUPER_ADMIN');
    }

    return false;
  },
  isLoggedIn(state): boolean {
    return state.isLoggedIn;
  },
};

export default getters;
