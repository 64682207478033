import Vue from 'vue';
import { ActionTree } from 'vuex';
import { RootState } from '@/types/store/RootState';
import { HierarchyState } from '@/types/store/HierarchyState';
import { SET_HIERARCHY_NODES } from '@/store/hierarchy/mutation-types';

const actions: ActionTree<HierarchyState, RootState> = {
  async loadHierarchyNodes({ commit }) {
    const response = await Vue.prototype.$api.get('/api/hierarchy_nodes?exists[parent]=false');
    if (response.status === 200 && response.data['hydra:member']) {
      commit(SET_HIERARCHY_NODES, response.data['hydra:member']);
    }
  },
};

export default actions;
