import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const localStorageUser = window?.localStorage.getItem('user');
  if (!store.getters['auth/isLoggedIn'] && localStorageUser) {
    try {
      await store.dispatch('auth/getUser', JSON.parse(localStorageUser));
    } catch (e) {
      await store.dispatch('auth/logout');
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    }
  }

  const isLoggedIn = store.getters['auth/isLoggedIn'];

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isLoggedIn) {
      if (
        (to.params.resourceTitle === 'templates' || to.name === 'settings')
        && !store.getters['auth/isSuperAdmin']
      ) {
        next({ path: '/', params: { nextUrl: to.fullPath } });
      } else {
        next();
      }
    } else {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath },
      });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (isLoggedIn) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next({
      path: '/login',
      params: { nextUrl: to.fullPath },
    });
  }
});

export default router;
