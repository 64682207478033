
import Draggable from 'vuedraggable';
import { Component, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { EditFormInterface } from '@/types/interfaces/EditResource/EditFormInterface';
import { Template } from '@/types/resources/Template';
import { mixins } from 'vue-class-component';
import TemplateSegmentForm from '@/components/resources/Template/TemplateSegmentForm.vue';
import TemplateMixin from '@/mixins/Template/TemplateMixin';

@Component({
  components: { Draggable, TemplateSegmentForm, ValidationProvider },
})
export default class TemplateEditForm extends mixins(TemplateMixin) implements EditFormInterface {
  @Prop({ type: Object, required: true }) resource!: Template;

  loadLocalResource(): void {
    this.template = this.resource;
  }

  resetLocalResource(): void {
    this.loadLocalResource();
  }
}
