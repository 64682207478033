import { Module } from 'vuex';
import { AuthState } from '@/types/store/AuthState';
import { RootState } from '@/types/store/RootState';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const state: AuthState = {
  user: undefined,
  isLoggedIn: false,
};

const namespaced = true;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
