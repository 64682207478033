import { MutationTree } from 'vuex';
import { SET_API } from '@/store/api/mutation-types';
import { ApiState } from '@/types/store/ApiState';

const mutations: MutationTree<ApiState> = {
  [SET_API](state, payload) {
    Object.keys(payload).forEach((property) => {
      state[property] = payload[property];
    });
  },
};

export default mutations;
