import { Module } from 'vuex';
import getters from '@/store/hierarchy/getters';
import actions from '@/store/hierarchy/actions';
import mutations from '@/store/hierarchy/mutations';
import { RootState } from '@/types/store/RootState';
import { HierarchyState } from '@/types/store/HierarchyState';

export const state: HierarchyState = {
  hierarchyNodes: [],
};

const namespaced = true;

export const hierarchy: Module<HierarchyState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
