import { HierarchyNode, HierarchyNodeType } from '@/types/resources/HierarchyNode';

export const hierarchyNodeTypes = [
  HierarchyNodeType.none,
  HierarchyNodeType.segmentOnChild,
  HierarchyNodeType.segmentOnParent,
];

export function defaultHierarchyNode(position = 0, useNone = false): Partial<HierarchyNode> {
  return {
    name: '',
    template: null,
    segment: null,
    type: useNone ? HierarchyNodeType.none : HierarchyNodeType.segmentOnChild,
    children: [],
    position,
  };
}
