/**
 * Inspiration from https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
 * Iterates through array executing an asynchronous callback function on every element.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any,import/prefer-default-export
export async function asyncForEach(array: any[], callback: (...args: any[]) => any) {
  for (let index = 0; index < array.length; index += 1) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}
