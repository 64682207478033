import en from 'vee-validate/dist/locale/en.json';

export default {
  fields: {
    caption: 'Caption',
    contentBlockType: 'Content Type',
    contentTemplate: 'Content template',
    email: 'Email',
    file: 'File',
    name: 'Name',
    slug: 'Slug',
    status: 'Status',
    text: 'Text',
    password: 'Password',
  },
  'fields.translations[de].slug': 'Slug',
  'fields.translations[de].name': 'Name',
  'fields.translations[de].status': 'State',
  validation: {
    ...en.messages,
    general: 'This value is invalid',
  },
};
