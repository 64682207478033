
import {
  Vue, Component, PropSync, Prop,
} from 'vue-property-decorator';
import Quill from 'quill';
import { ValidationProvider } from 'vee-validate';
import { TextContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { toolbarOptions } from '@/utils/quill';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
// eslint-disable-next-line
// @ts-ignore
import GlossaryKeyword from '@/utils/quill/glossaryKeyword';

Quill.register({
  'formats/glossaryKeyword': GlossaryKeyword,
});

const icons = Quill.import('ui/icons');
icons.undo = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;
icons.redo = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;
icons.glossary = `<svg viewBox="10 10 80 80" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <g>
      <path class="ql-fill" d="M65.9,22.6c-5.1-4.5-11.9-6.7-18.8-5.9c-11.9,1.4-21.1,11.9-21,23.9c0.1,7,3.4,11.6,6.9,16.5c1.1,1.5,2.2,3.1,3.3,4.8   c0.1,0.2,0.3,0.6,0.4,2.1l0,0c0,0.5,0.1,1,0.1,1.5c0,0.5,0.1,1,0.1,1.5c0.1,1.2,0.2,3.4,0.3,5.4c0.1,1.6,0.2,3.2,0.2,4   c0.2,2.4,2.2,4.3,4.7,4.3h0.6H43c0.8,1.6,2.4,2.6,4.2,2.6h5.6c1.8,0,3.4-1,4.2-2.6h0.1h0.8c2.4,0,4.5-1.9,4.7-4.3   c0.1-0.8,0.1-2.4,0.2-4c0.1-2,0.2-4.3,0.3-5.4c0-0.5,0.1-1,0.1-1.5c0-0.5,0.1-1,0.1-1.5l0,0c0.1-1.5,0.3-1.9,0.4-2.1   c1-1.7,2.2-3.3,3.3-4.8c3.5-5,6.9-9.7,6.9-16.7C73.9,33.6,71,27.1,65.9,22.6z M60,66.8c-0.1,1.2-0.2,3.4-0.3,5.6   c-0.1,1.6-0.2,3.1-0.2,3.9c-0.1,0.8-0.7,1.4-1.6,1.4h-0.8h-0.6H43.5h-0.8h-0.6c-0.8,0-1.5-0.6-1.6-1.4c-0.1-0.8-0.1-2.3-0.2-3.9   c-0.1-2.1-0.2-4.3-0.3-5.6c0-0.5-0.1-0.9-0.1-1.4h20.2C60.1,65.9,60,66.4,60,66.8z"></path>
    </g>
  </svg>`;

@Component({
  components: { ContentBlockFormWrapper, ValidationProvider },
})
export default class TextContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<TextContentBlockInterface>;

  // eslint-disable-next-line
  private quill: any;

  get textType(): 'div' | 'textarea' | 'input' {
    if (this.syncedContentBlock.config) {
      if (this.syncedContentBlock.config.wysiwyg === true) {
        return 'div';
      }
      if (this.syncedContentBlock.config.multiline === true) {
        return 'textarea';
      }
    }

    return 'input';
  }

  initializeQuill() {
    if (this.textType === 'div') {
      this.quill = new Quill(this.$refs[`editor_${this.syncedContentBlock.name}_${this.locale}`], {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              glossary(this: any, value: boolean) {
                if (value) {
                  const range = this.quill.getSelection();
                  if (range == null) return;
                  const [kw, off] = this.quill.scroll.descendant(GlossaryKeyword, range.index);
                  let defaultValue = '';
                  if (kw != null) {
                    defaultValue = kw.domNode.getAttribute('data-glossary-keyword');
                    this.quill.setSelection(range.index - off, kw.length(), Quill.sources.SILENT);
                  } else if (range.length === 0) {
                    // If no existing keyword: ensure we have a selection
                    return;
                  }
                  const keyword = prompt('Enter keyword:', defaultValue);
                  if (keyword !== null) {
                    this.quill.format('glossaryKeyword', keyword, Quill.sources.USER);
                  }
                }
              },
              undo(this: any) {
                this.quill.history.undo();
              },
              redo(this: any) {
                this.quill.history.redo();
              },
            },
          },
          history: true,
        },
        theme: 'snow',
      });

      if (this.syncedContentBlock.text) {
        const delta = this.quill.clipboard.convert(
          this.syncedContentBlock.translations[this.locale].text,
        );
        this.quill.setContents(delta, 'silent');
      }

      this.quill.on('text-change', () => {
        this.syncedContentBlock.translations[this.locale].text = this.quill.root.innerHTML;
      });
    }
  }

  mounted() {
    this.$nextTick(async () => {
      if (
        !this.syncedContentBlock.config
        && this.syncedContentBlock.templateSegment
        && typeof this.syncedContentBlock.templateSegment === 'object'
      ) {
        const response = await this.$api.get(this.syncedContentBlock.templateSegment['@id']);
        if (response.status === 200) {
          this.syncedContentBlock.config = response.data.config;
        }
      }

      this.initializeQuill();
    });
  }
}
