
import { Component, Prop, Watch } from 'vue-property-decorator';
import { CreateFormInterface } from '@/types/interfaces/CreateResource/CreateFormInterface';
import { ValidationProvider } from 'vee-validate';
import { Treeselect } from '@riophae/vue-treeselect';
import { Content } from '@/types/resources/Content';
import { Template } from '@/types/resources/Template';
import { ContentStatus } from '@/types/Enums';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { mixins } from 'vue-class-component';
import TranslationsMixin from '@/mixins/TranslationsMixin';
import CreateContentBlockMixin from '@/mixins/CreateContentBlockMixin';
import { Translatable } from '@/types/resources/Translatable';
import ContentMixin from '@/mixins/ContentMixin';

@Component({
  components: { ValidationProvider, Treeselect },
})
export default class ContentCreateForm
  extends mixins(TranslationsMixin, ContentMixin, CreateContentBlockMixin)
  implements CreateFormInterface {
  @Prop({ type: Array, default: () => [] }) templateIds!: string[];

  @Prop({ type: Boolean, default: false }) isModal!: boolean;

  private templates: Template[] = [];

  private localLocaleIndex = 0;

  protected content: Partial<Translatable<Content>>
    & { contentBlocks?: Partial<ContentBlockInterface>[] } = {
      translations: {},
      contentBlocks: [],
    };

  get showTemplateDropdown(): boolean {
    if (this.templateIds.length === 1) {
      return false;
    }

    // noinspection RedundantIfStatementJS
    if (!this.isModal && this.templateIds.length === 0 && this.$route.params.templateId) {
      return false;
    }

    return true;
  }

  get defaultLocale(): string {
    return process.env.VUE_APP_DEFAULT_LOCALE;
  }

  get templateOptions(): { id: string; label: string }[] {
    return this.templates.map((t) => ({
      id: t['@id'],
      label: t.name,
    }));
  }

  get template(): Template | undefined {
    if (this.content.template) {
      return this.templates.find((t) => t['@id'] === this.content.template);
    }

    return undefined;
  }

  get statuses(): { value: string; text: string }[] {
    return Object.values(ContentStatus).map((s) => ({
      value: s,
      text: this.$t(`resource.Content.status.${s}`) as string,
    }));
  }

  // prepareLocalResource() in ContentMixin

  resetLocalResource(): void {
    this.content = this.setPropertiesForAllLocales({}, {
      name: '',
      slug: '',
      status: ContentStatus.published,
    });
  }

  async created(): Promise<void> {
    try {
      if (!this.isModal) {
        if (this.templateIds.length === 0 && !this.$route.params.templateId) {
          const response = await this.$api.get('/api/templates', {
            params: { properties: ['name', 'segments'] },
          });
          if (response.status === 200 && response.data['hydra:member']?.length) {
            this.templates = response.data['hydra:member'];
          }
        } else if (this.templateIds.length > 1) {
          const response = await this.$api.get('/api/templates', {
            params: { id: this.templateIds },
          });
          if (response.status === 200 && response.data['hydra:member']?.length) {
            this.templates = response.data['hydra:member'];
          }
        } else {
          const templateId = this.templateIds[0] ?? this.$route.params.templateId;
          const templateResponse = await this.$api.get(`/api/templates/${templateId}`);
          if (templateResponse?.status === 200 && templateResponse.data) {
            this.content.template = templateResponse.data['@id'];
            this.initializeContentBlocksFromTemplate(templateResponse.data);
            this.$forceUpdate();
          }
        }
      } else if (this.templateIds.length === 0) {
        const response = await this.$api.get('/api/templates', {
          params: { properties: ['name', 'segments'] },
        });
        if (response.status === 200 && response.data['hydra:member']?.length) {
          this.templates = response.data['hydra:member'];
        }
      } else if (this.templateIds.length > 1) {
        const response = await this.$api.get('/api/templates', {
          params: { id: this.templateIds },
        });
        if (response.status === 200 && response.data['hydra:member']?.length) {
          this.templates = response.data['hydra:member'];
        }
      } else if (this.templateIds.length === 1) {
        const templateId = this.templateIds[0];
        const templateResponse = await this.$api.get(`/api/templates/${templateId}`);
        if (templateResponse?.status === 200 && templateResponse.data) {
          this.content.template = templateResponse.data['@id'];
          this.initializeContentBlocksFromTemplate(templateResponse.data);
          this.$forceUpdate();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  @Watch('template')
  loadContentBlocks(value: Template): void {
    this.initializeContentBlocksFromTemplate(value);
  }
}
