/* eslint-disable no-param-reassign */

import { Vue, Component } from 'vue-property-decorator';
import { Translatable } from '@/types/resources/Translatable';
import { CourseQuestionContentBlockInterface } from '@/types/resources/ContentBlockInterface';

@Component
export default class CourseQuestionContentBlockMixin extends Vue {
  async loadCourseQuestionContentBlock(block: Translatable<CourseQuestionContentBlockInterface>) {
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      const response = await this.$api.get(block.templateSegment['@id']);
      if (response.status === 200) {
        block.templateSegment = response.data['@id'];
        block.config = response.data.config;
        block.questionType = response.data.questionType;
      }

      this.$root.$i18n.availableLocales.forEach((locale) => {
        if (block.translations[locale] === undefined) {
          block.translations[locale] = {
            id: undefined,
            questionData: {},
            answerData: {},
            questionText: '',
            answerText: '',
            name: block.name,
            locale,
          };
        }
      });
    }
  }

  prepareCourseQuestionContentBlock(block: Translatable<CourseQuestionContentBlockInterface>):
    Partial<Translatable<CourseQuestionContentBlockInterface>> | undefined {
    let templateSegment: string | undefined;
    if (block.templateSegment && typeof block.templateSegment === 'object') {
      templateSegment = block.templateSegment['@id'];
    } else {
      templateSegment = block.templateSegment;
    }

    const preparedBlock: Partial<Translatable<CourseQuestionContentBlockInterface>> = {
      id: this.$route.query.duplicate ? undefined : block['@id'],
      templateSegment,
      translations: {},
      questionType: block.questionType,
      type: 'courseQuestion',
    };

    Object.keys(block.translations).forEach((locale) => {
      if (preparedBlock.translations != null) {
        if (block.translations[locale]) {
          preparedBlock.translations[locale] = {
            id: this.$route.query.duplicate ? undefined : block.translations[locale]['@id'],
            templateSegment,
            questionData: block.translations[locale]?.questionData,
            answerData: block.translations[locale]?.answerData,
            questionText: block.translations[locale]?.questionText,
            answerText: block.translations[locale]?.answerText,
            name: block.translations[locale].name,
            locale,
          };
        }
      }
    });

    // eslint-disable-next-line
    // @ts-ignore
    if (Object.keys(preparedBlock.translations).length === 0) {
      return undefined;
    }

    return preparedBlock;
  }
}
