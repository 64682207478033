import { Vue, Component } from 'vue-property-decorator';
import { Template } from '@/types/resources/Template';
import { TemplateStatus } from '@/types/Enums';

@Component
export default class TemplateMixin extends Vue {
  protected template: Partial<Template> | null = {};

  prepareLocalResource(): Partial<Template> | null {
    if (this.template?.segments?.length) {
      // If there are TemplateSegments, check if their ID starts with __
      // In that case, it's a frontend id and needs to be removed.
      const segments = this.template.segments.map((s) => {
        if (s['@id']?.startsWith('__')) {
          return { ...s, id: undefined, '@id': undefined };
        }

        if (this.$route.query.duplicate) {
          return { ...s, '@id': undefined, id: undefined };
        }

        return {
          ...s,
          '@id': undefined,
          id: s['@id'],
        };
      });

      if (this.$route.query.duplicate) {
        return {
          ...this.template, segments, '@id': undefined, id: undefined,
        };
      }

      return {
        ...this.template,
        segments,
      };
    }

    if (this.$route.query.duplicate) {
      return { ...this.template, '@id': undefined, id: undefined };
    }

    return this.template;
  }

  get statuses(): { value: string; text: string }[] {
    const statuses = Object.values(TemplateStatus);
    return statuses.map((s: string) => ({
      value: s,
      text: this.$t(`resource.Template.status.${s}`) as string,
    }));
  }

  addTemplateSegment() {
    if (this.template?.segments) {
      this.template.segments.push({
        '@id': `__${Math.random().toString(20).slice(2, 10)}`,
        name: '',
        position: this.template.segments.length,
        required: true,
        config: {},
      });
    } else {
      throw new Error('template.segments is empty, but shouldn\' be.');
    }
  }

  removeTemplateSegment(id: string) {
    if (this.template?.segments?.length) {
      const idx = this.template.segments.findIndex((s) => s['@id'] === id);
      if (idx > -1) {
        this.template.segments.splice(idx, 1);
        this.updateSegmentPositions();
      }
    }
  }

  updateSegmentPositions() {
    if (this.template?.segments?.length) {
      for (let i = 0; i < this.template.segments.length; i += 1) {
        this.template.segments[i].position = i;
      }
    }
  }
}
