
import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';

  @Component({
    components: { ContentBlockFormWrapper, ValidationProvider },
  })
// eslint-disable-next-line max-len
export default class OsappAnimationViewContentBlockForm extends Vue implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<ContentBlockInterface>;

  openConfiguratorWindow(textConfig: string, cameraConfig: string): void {
    const popup = window.open(this.getConfigLink(JSON.parse(textConfig), JSON.parse(cameraConfig)));
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data.type === 'osappAnimConfigChanged' && (event.data.configId === this.syncedContentBlock['@id'] || (event.data.configId === null && typeof this.syncedContentBlock['@id'] === 'undefined'))) {
        // this.syncedContentBlock.configuration = event.data.payload;
        this.$set(this.syncedContentBlock, 'configuration', event.data.payload);
      }
      if (event.data.type === 'osappAnimCameraChanged' && (event.data.configId === this.syncedContentBlock['@id'] || (event.data.configId === null && typeof this.syncedContentBlock['@id'] === 'undefined'))) {
        // this.syncedContentBlock.cameraConfiguration = event.data.payload;
        this.$set(this.syncedContentBlock, 'cameraConfiguration', event.data.payload);
      }
    }, false);
  }

  getConfigLink(config: Record<string, any>, cameraConfig: Record<string, any>): string {
    if (this.syncedContentBlock['@id']) {
      return `${process.env.VUE_APP_FRONTEND_BASE_URL}/vtkompose?embedded=1&configId=${this.syncedContentBlock['@id']}&config=${btoa(JSON.stringify([config]))}&camera-config=${btoa(JSON.stringify(cameraConfig))}`;
    }
    return `${process.env.VUE_APP_FRONTEND_BASE_URL}/vtkompose?embedded=1&config=${btoa(JSON.stringify([config]))}&camera-config=${btoa(JSON.stringify(cameraConfig))}`;
  }

  get textConfig() {
    return JSON.stringify(this.syncedContentBlock?.configuration);
  }

  set textConfig(val) {
    try {
      this.$set(this.syncedContentBlock, 'configuration', JSON.parse(val));
    } catch (e) {
      console.error(e);
    }
  }

  get textCameraConfig() {
    if (this.syncedContentBlock && this.syncedContentBlock.cameraConfiguration) {
      return JSON.stringify(this.syncedContentBlock?.cameraConfiguration);
    }
    return '{}';
  }

  set textCameraConfig(val) {
    try {
      this.$set(this.syncedContentBlock, 'cameraConfiguration', JSON.parse(val));
    } catch (e) {
      console.error(e);
    }
  }

  get allowPanning() {
    if (this.syncedContentBlock) {
      return this.syncedContentBlock.allowPanning;
    }
    return false;
  }

  set allowPanning(val) {
    this.syncedContentBlock.allowPanning = val;
  }

  mounted() {
    this.$nextTick(async () => {
      if (
        !this.syncedContentBlock.configuration
        && this.syncedContentBlock.templateSegment
        && typeof this.syncedContentBlock.templateSegment === 'object'
      ) {
        const response = await this.$api.get(this.syncedContentBlock.templateSegment['@id']);
        if (response.status === 200) {
          this.$set(this.syncedContentBlock, 'configuration', response.data.configuration);
          this.$set(this.syncedContentBlock, 'cameraConfiguration', response.data.cameraConfiguration);
          this.$set(this.syncedContentBlock, 'allowPanning', response.data.allowPanning);
        }
      }
    });
  }
}
