import { Module } from 'vuex';
import getters from '@/store/api/getters';
import actions from '@/store/api/actions';
import mutations from '@/store/api/mutations';
import { ApiState } from '@/types/store/ApiState';
import { RootState } from '@/types/store/RootState';

export const state: ApiState = {
  entrypoint: '',
  resources: [],
  title: '',
};

const namespaced = true;

export const api: Module<ApiState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
