
import {
  Vue, Component, PropSync, Prop, Watch,
} from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import {
  ContentBlockInterface,
  CourseQuestionContentBlockInterface,
} from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import { Translatable } from '@/types/resources/Translatable';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';

@Component({
  components: { ContentBlockFormWrapper, ValidationProvider },
})
export default class CourseQuestionContentBlockForm extends Vue implements
  ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @Prop({ type: Boolean, default: false }) showDelete!: boolean;

  @Prop({ type: Boolean, default: false }) draggable!: boolean;

  @PropSync('contentBlock', {
    type: Object,
    required: true,
  }) syncedContentBlock!: Translatable<ContentBlockInterface>;

  syncedContentBlockCopy: Translatable<CourseQuestionContentBlockInterface> = {
    '@context': '',
    '@id': '',
    '@type': '',
    translations: {},
  };

  availableControls = [
    'angle',
    'loadType',
    'location',
    'plateSize',
    'screwType',
    'loadFactor',
    'fractureGap',
    'screwConfig',
    'fractureType',
    'healingState',
    'plateMaterial',
    'plateElevation',
    'fractureLocation',
    'fracturePosition',
  ];

  created() {
    if (!this.syncedContentBlock.questionType) {
      this.$set(this.syncedContentBlock, 'questionType', 'singleChoice');
    }
  }

  mounted() {
    window.addEventListener('message', this.configChangeListener, false);
    this.syncedContentBlockCopy = this.syncedContentBlock;
    if (!this.syncedContentBlock['@id']) {
      this.questionTypeChanged('singleChoice', '');
    }
    this.$watch('syncedContentBlock.questionType', this.questionTypeChanged);
  }

  destroy() {
    window.removeEventListener('message', this.configChangeListener, false);
  }

  // @Watch('syncedContentBlock.questionType')
  questionTypeChanged(value: string, oldValue: string) {
    if (value === oldValue) {
      return;
    }
    if (value === 'singleChoice') {
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'answerData', { solution: null });
      if (oldValue === 'multipleChoice') {
        return;
      }
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'questionData', { choices: [] });
    }
    if (value === 'multipleChoice') {
      if (oldValue === 'singleChoice') {
        this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'answerData', { solution: [this.syncedContentBlock.translations[this.selectedLocale].answerData.solution] });
        return;
      }
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'questionData', { choices: [] });
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'answerData', { solution: [] });
    }
    if (value === 'ordering') {
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'questionData', { elements: [] });
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'answerData', { solution: [] });
    }
    if (value === 'configurator') {
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'questionData', { controls: [], initialConfig: {}, cameraInitialConfig: {} });
      this.$set(this.syncedContentBlock.translations[this.selectedLocale], 'answerData', { solution: [], mainSolution: null });
    }
  }

  get textQuestionData() {
    if (this.syncedContentBlock && this.syncedContentBlock.translations[this.locale].questionData) {
      return JSON.stringify(this.syncedContentBlock?.translations[this.locale].questionData,
        null, 4);
    }
    return '{}';
  }

  set textQuestionData(val) {
    try {
      this.$set(this.syncedContentBlock.translations[this.locale], 'questionData', JSON.parse(val));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  get textAnswerData() {
    if (this.syncedContentBlock && this.syncedContentBlock.translations[this.locale].answerData) {
      return JSON.stringify(this.syncedContentBlock?.translations[this.locale].answerData,
        null, 4);
    }
    return '{}';
  }

  set textAnswerData(val) {
    try {
      this.$set(this.syncedContentBlock.translations[this.locale], 'answerData', JSON.parse(val));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  addOption() {
    this.$set(this.syncedContentBlock.translations[this.locale].questionData.choices, Object.keys(this.syncedContentBlock.translations[this.locale].questionData.choices).length, '');
  }

  removeOption(idx: number) {
    this.syncedContentBlock.translations[this.locale].questionData.choices.splice(idx, 1);
    if (this.syncedContentBlock.translations[this.locale].answerData.solution >= idx) {
      this.syncedContentBlock.translations[this.locale].answerData.solution -= 1;
    }
  }

  addSolution() {
    this.syncedContentBlock.translations[this.locale].answerData.solution.push({});
  }

  removeSolution(idx: number) {
    this.syncedContentBlock.translations[this.locale].answerData.solution.splice(idx, 1);
  }

  addOrderingElement() {
    this.$set(this.syncedContentBlock.translations[this.locale].questionData.elements, Object.keys(this.syncedContentBlock.translations[this.locale].questionData.elements).length, '');
    this.syncedContentBlock.translations[this.locale].answerData.solution.push(
      this.syncedContentBlock.translations[this.locale].questionData.elements.length - 1,
    );
  }

  removeOrderingElement(idx: number) {
    this.syncedContentBlock.translations[this.locale].questionData.elements.splice(idx, 1);
    this.syncedContentBlock.translations[this.locale].answerData.solution.splice(idx, 1);
  }

  openConfiguratorWindow(textConfig: string, cameraConfig: string): void {
    window.open(this.getConfigLink(JSON.parse(textConfig), JSON.parse(cameraConfig), 'osappConfigChanged', 'osappCameraChanged'));
  }

  configChangeListener(event: MessageEvent): void {
    if (event.data.type === 'osappConfigChanged' && (event.data.configId === this.syncedContentBlock['@id'] || (event.data.configId === null && typeof this.syncedContentBlock['@id'] === 'undefined'))) {
      // this.syncedContentBlock.configuration = event.data.payload;
      this.$set(this.syncedContentBlock.translations[this.locale].questionData, 'initialConfig', event.data.payload);
    }
    if (event.data.type === 'osappCameraChanged' && (event.data.configId === this.syncedContentBlock['@id'] || (event.data.configId === null && typeof this.syncedContentBlock['@id'] === 'undefined'))) {
      // this.syncedContentBlock.cameraConfiguration = event.data.payload;
      this.$set(this.syncedContentBlock.translations[this.locale].questionData, 'cameraInitialConfig', event.data.payload);
    }

    if (event.data.type && typeof event.data.type === 'string' && event.data.type.startsWith('osappSolutionConfigChanged_') && (event.data.configId === this.syncedContentBlock['@id'] || (event.data.configId === null && typeof this.syncedContentBlock['@id'] === 'undefined'))) {
      // this.syncedContentBlock.configuration = event.data.payload;
      const idx = parseInt(event.data.type.split('_')[1], 10);
      this.$set(this.syncedContentBlock.translations[this.locale].answerData.solution,
        idx, event.data.payload.modelConfig);
    }
  }

  openSolutionConfiguratorWindow(config: string, idx: number): void {
    window.open(this.getConfigLink({ modelConfig: config }, {}, `osappSolutionConfigChanged_${idx}`, `osappSolutionCameraChanged_${idx}`));
  }

  getConfigLink(config: Record<string, any>,
    cameraConfig: Record<string, any>, configEventName: string, cameraEventName: string): string {
    if (this.syncedContentBlock['@id']) {
      return `${process.env.VUE_APP_FRONTEND_BASE_URL}/free_configurator?embedded=1&config-event-name=${configEventName}&camera-event-name=${cameraEventName}&configId=${this.syncedContentBlock['@id']}&gap-strain-gradient&mapping-ranges&config=${btoa(JSON.stringify([config]))}&camera-config=${btoa(JSON.stringify(cameraConfig))}`;
    }
    return `${process.env.VUE_APP_FRONTEND_BASE_URL}/free_configurator?embedded=1&config-event-name=${configEventName}&camera-event-name=${cameraEventName}&gap-strain-gradient&mapping-ranges&config=${btoa(JSON.stringify([config]))}&camera-config=${btoa(JSON.stringify(cameraConfig))}`;
  }

  get textInitialConfig() {
    if (this.syncedContentBlock.translations[this.locale]?.questionData?.initialConfig) {
      return JSON.stringify(
        this.syncedContentBlock.translations[this.locale]?.questionData?.initialConfig,
      );
    }
    return '{}';
  }

  set textInitialConfig(val) {
    try {
      this.$set(this.syncedContentBlock.translations[this.locale].questionData, 'initialConfig', JSON.parse(val));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  get textCameraInitialConfig() {
    if (this.syncedContentBlock.translations[this.locale]?.questionData?.cameraInitialConfig) {
      return JSON.stringify(
        this.syncedContentBlock.translations[this.locale]?.questionData?.cameraInitialConfig,
      );
    }
    return '{}';
  }

  set textCameraInitialConfig(val) {
    try {
      this.$set(this.syncedContentBlock.translations[this.locale].questionData, 'cameraInitialConfig', JSON.parse(val));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}
