
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentBlockInterface } from '@/types/resources/ContentBlockInterface';
import { Translatable } from '@/types/resources/Translatable';

@Component
export default class ContentBlockFormWrapper extends Vue {
  @Prop({ type: Object, default: null })
  contentBlock!: Partial<Translatable<ContentBlockInterface>>;

  @Prop({ type: Boolean, default: false }) draggable!: boolean
}
