
import {
  Component, Prop, PropSync,
} from 'vue-property-decorator';
import { ContentBlockFormInterface } from '@/types/interfaces/Content/ContentBlockFormInterface';
import {
  CollectionContentBlockInterface,
  ContentBlockInterface,
} from '@/types/resources/ContentBlockInterface';
import ContentBlockFormWrapper
  from '@/components/resources/Content/ContentBlock/ContentBlockFormWrapper.vue';
import Draggable from 'vuedraggable';
import { mixins } from 'vue-class-component';
import CreateContentBlockMixin from '@/mixins/CreateContentBlockMixin';
import { contentBlockTypes } from '@/utils/ContentBlockTypes';
import { Translatable } from '@/types/resources/Translatable';

@Component({
  components: { ContentBlockFormWrapper, Draggable },
})
export default class CollectionContentBlockForm
  extends mixins(CreateContentBlockMixin) implements ContentBlockFormInterface {
  @Prop({ type: String, required: true }) locale!: string;

  @Prop({ type: String, required: true }) selectedLocale!: string;

  @PropSync('contentBlock', { type: Object, required: true })
  syncedContentBlock!: Partial<CollectionContentBlockInterface> & {
    contentBlocks: Partial<ContentBlockInterface>[];
  };

  private nextBlockType: string | null = null;

  get contentBlockTypes() {
    return this.syncedContentBlock.config.allowedTypes.map((type: string) => ({
      value: type,
      text: this.$t(`resource.${this.getContentBlockFromType(type)}.label`),
    }));
  }

  getContentBlockFromType(type: string): string {
    return `${type.slice(0, 1).toUpperCase()}${type.slice(1)}ContentBlock`;
  }

  getContentBlockComponentFromType(type: string): string {
    return `${type.slice(0, 1).toUpperCase()}${type.slice(1)}ContentBlockForm`;
  }

  updateContentBlockPositions() {
    for (let i = 0; i < this.syncedContentBlock.contentBlocks.length; i += 1) {
      this.syncedContentBlock.contentBlocks[i].position = i;
    }
  }

  addContentBlock(specifiedType: string | null, specifiedPosition = null) {
    let type = this.syncedContentBlock.config.allowedTypes[0];
    if (specifiedType) {
      type = specifiedType;
    }
    const contentBlockConfiguration = Object.values(contentBlockTypes).find(
      (b) => b.name === type,
    );
    if (contentBlockConfiguration && type) {
      const position = specifiedPosition ?? this.syncedContentBlock.contentBlocks.length;
      const newBlockName = `${this.syncedContentBlock.name}_${
        this.$t(`resource.${this.getContentBlockFromType(contentBlockConfiguration.name)}.label`)
      }_${position}`;

      let block: Partial<Translatable<ContentBlockInterface>> = {
        type: contentBlockConfiguration.name,
        name: newBlockName,
        config: this.syncedContentBlock.config?.config?.[type] ?? null,
        templateSegment: null,
        translations: {},
        position,
      };

      block = this.initializeContentBlock(contentBlockConfiguration, block, newBlockName);

      // Add the contentBlock to the content
      this.syncedContentBlock.contentBlocks.push(block);

      this.$forceUpdate();
    }
  }

  removeContentBlock(name: string) {
    const updatedBlockIdx = this.syncedContentBlock.contentBlocks.findIndex(
      (block) => `collection_${block.name}` === name,
    );
    if (updatedBlockIdx === -1) {
      return;
    }
    this.syncedContentBlock.contentBlocks.splice(updatedBlockIdx, 1);

    this.$forceUpdate();
  }

  changeCollectionItemType(type: string, name: string) {
    const updatedBlockIdx = this.syncedContentBlock.contentBlocks.findIndex(
      (block) => block.name === name,
    );
    const position = this.syncedContentBlock.contentBlocks[updatedBlockIdx].position ?? null;

    this.syncedContentBlock.contentBlocks.splice(updatedBlockIdx, 1);
    this.addContentBlock(type, position);

    this.$forceUpdate();
  }
}
