import { MutationTree } from 'vuex';
import { SET_SHOWN_PROPERTIES } from '@/store/listView/mutation-types';
import { ListViewState } from '@/types/store/ListViewState';

const mutations: MutationTree<ListViewState> = {
  /**
   * @param state
   * @param {object} payload
   * @param {string} payload.resource - Resource for which shown properties are stored
   * @param {array} payload.shownProperties - Which properties are shown
   */
  [SET_SHOWN_PROPERTIES](state, payload) {
    state[payload.resource] = payload.shownProperties;
  },
};

export default mutations;
