import HierarchyNodes from '@/components/resources/Settings/Hierarchy/HierarchyNodes.vue';
import HierarchyNodeForm from '@/components/resources/Settings/Hierarchy/HierarchyNodeForm.vue';
import HierarchyNodeView from '@/components/resources/Settings/Hierarchy/HierarchyNodeView.vue';
// Core
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

// Plugins
import './plugins/axios';
import './plugins/bootstrap';
import './plugins/fontawesome';
import './plugins/v-calendar';
import './plugins/veevalidate';

// SCSS
import './assets/scss/style.scss';

const requireResourceComponents = require.context(
  '@/components/resources',
  true,
  /[A-Z]\w+(Edit|Create|ContentBlock)Form\.vue$/,
);

requireResourceComponents.keys().forEach((fileName) => {
  const componentConfig = requireResourceComponents(fileName);

  // Get PascalCase name of component
  const componentName = fileName.split('/')?.pop()?.replace(/\.\w+$/, '');

  if (componentName) {
    Vue.component(componentName, componentConfig.default || componentConfig);
  }
});

Vue.component('hierarchy-nodes', HierarchyNodes);
Vue.component('hierarchy-node-form', HierarchyNodeForm);
Vue.component('hierarchy-node-view', HierarchyNodeView);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
