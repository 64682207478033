import validation from './validation/en';

export default {
  ...validation,
  create: {
    Content: {
      name: 'Name',
      contentBlocks: {
        CollectionContentBlock: {
          contentBlockType: 'Inhaltsblocktyp',
        },
        ReferenceContentBlock: {
          checkDefaultLocale: 'This reference is set in locale "{0}".',
        },
      },
      searchTerms: 'Search Terms',
      slug: 'Slug',
      status: 'State',
      success: 'Content saved',
      template: {
        select: 'Select template',
      },
      title: 'Add content',
    },
    ImageObject: {
      browse: 'Select',
      caption: 'Captioon',
      drop: 'Drop image here',
      file: 'Image file',
      fileSize: 'File size',
      placeholder: 'Select image',
      selectedFile: 'Selected image',
      success: 'ImageObject saved',
      title: 'ImageObject edit',
    },
    label: 'Create',
    MediaObject: {
      browse: 'Browse',
      drop: 'Drop file',
      fileSize: 'File size',
      label: 'File',
      placeholder: 'Select file',
      selectedFile: 'Selected File',
      success: 'File saved',
      title: 'Edit MediaObject',
    },
    something: '{0} erstellen',
    submitResource: 'Save {0}',
    Template: {
      name: 'Name',
      success: 'Template saved',
      status: 'Status',
      segment: {
        configuration: 'Configuration',
        contentBlockType: 'Content Type',
        name: 'Name',
        required: 'Mandatory Segment',
      },
      title: 'Create Template',
    },
    User: {
      email: 'Email',
      success: 'User saved',
      title: 'Edit user {name}',
      password: 'Password',
    },
    Question: {
      questionType: 'Question Type',
      questionData: 'Question Data',
      answerData: 'Answer Data',
      questionText: 'Question Text',
      answerText: 'Answer Description Text',
    },
  },
  edit: {
    Content: {
      name: 'Name',
      contentBlocks: {
        ReferenceContentBlock: {
          checkDefaultLocale: 'This reference is set in locale "{0}".',
        },
      },
      slug: 'Slug',
      searchTerms: 'Search Terms',
      status: 'State',
      success: 'Content saved',
      template: {
        select: 'Select template',
      },
      title: 'Edit content',
    },
    ImageObject: {
      browse: 'Select',
      caption: 'Captioon',
      drop: 'Drop image here',
      file: 'Image file',
      fileSize: 'File size',
      placeholder: 'Select image',
      selectedFile: 'Selected image',
      success: 'ImageObject saved',
      title: 'ImageObject edit',
    },
    MediaObject: {
      browse: 'Browse',
      drop: 'Drop file',
      fileSize: 'File size',
      label: 'File',
      placeholder: 'Select file',
      selectedFile: 'Selected File',
      success: 'File saved',
      title: 'Edit MediaObject',
    },
    submitResource: 'Save {0}',
    Template: {
      name: 'Name',
      success: 'Template saved',
      status: 'Status',
      segment: {
        configuration: 'Configuration',
        contentBlockType: 'Content Type',
        name: 'Name',
        required: 'Mandatory Segment',
      },
      title: 'Edit Template',
    },
    User: {
      email: 'Email',
      success: 'User saved',
      title: 'Edit user {name}',
      password: 'Password',
      roles: 'Roles',
    },
  },
  error: {
    loadingProperty: 'Error while loading value',
    notEditable: 'This resource cannot be edited',
    resourceNotFound: 'The requested resource was not found',
  },
  filter: {
    label: 'Filter|Filters',
    noFilterSet: 'You have set no filters',
    set: 'Set filters',
  },
  global: {
    add: 'Add',
    addSomething: 'Add {0}',
    back: 'Back',
    cancel: 'Cancel',
    deletedSomething: 'Deleted "{0}"',
    deletionUndone: 'Deletion of "{0}" has been undone',
    edit: 'Edit',
    errorWhileDeleting: 'There was an error deleting the element. Try again later',
    loading: 'Loading',
    noResult: 'No results found',
    saved: 'Saved',
    search: 'Search',
    select: 'Select',
    settings: 'Settings',
    submit: 'Submit',
    typeToSearch: 'Type to search',
    undo: 'Undo',
    unknownError: 'An unknown error occured',
    upload: 'Upload',
  },
  list: {
    selectProperties: {
      title: 'Properties to show',
    },
  },
  login: {
    welcome: 'Welcome to the JKCMS 3000!',
  },
  nav: {
    menuLabel: 'Menu',
  },
  resource: {
    Content: {
      label: 'Content',
      status: {
        preview: 'Preview',
        published: 'Published',
      },
    },
    ContentBlockCollection: {
      label: 'Block collection',
    },
    EmptyContentBlock: {
      label: 'Neuer leerer Block',
    },
    ImageContentBlock: {
      label: 'Image block',
    },
    ImageObject: {
      label: 'ImageObject',
    },
    FileContentBlock: {
      label: 'File block',
    },
    MediaObject: {
      label: 'MediaObject',
    },
    ReferenceContentBlock: {
      label: 'Reference block',
    },
    Template: {
      label: 'Template',
      segment: {
        add: 'Add Template segment',
        label: 'Template segment|Template segments',
        labelShort: 'Segment|Segments',
      },
      status: {
        preview: 'Preview',
        published: 'Published',
      },
    },
    TextContentBlock: {
      label: 'Text block',
    },
    User: {
      label: 'User',
    },
  },
  settings: {
    hierarchy: {
      errorWhenDeletingNode: 'An error occurred. Please reload the page.',
      node: {
        children: 'Children',
        name: 'Name',
        onlyRoots: 'Only show root nodes',
        template: 'Template',
        segment: 'Segment',
        type: {
          label: 'Type',
          none: 'None',
          segmentOnParent: 'Segment on parent',
          segmentOnchild: 'Segment on child',
        },
      },
      title: 'Content hierarchy',
      savedButErrorWhileLoading: 'The hierarchy was saved but could not be loaded.',
    },
  },
};
